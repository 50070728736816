<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="mb-6">
      <p class="font-bold">report format</p>
      <span class="form-hint">your download will begin automatically after clicking the export to excel button</span>
    </div>
    <div v-if="this.community" class="form-row mb-6">
      <div class="inline-block mr-5" v-bind:class="{ active: currentTab == 1 }">
        <label class="cursor-pointer" @click="selectTab(1)">global resposes</label>
      </div>
      <div class="inline-block ml-5" v-bind:class="{ active: currentTab == 2 }">
        <label class="cursor-pointer" @click="selectTab(2)">custom responses</label>
      </div>
    </div>
    <div v-if="this.community" class="flex-1 flex-col overflow-auto">
      <div v-if="currentTab === 1" class="flex-1 flex-col overflow-auto">
        <List
          resource="previewConversationGlobalReport"
          :fields="globalFields"
          ref="list_custom"
          :pageSize="18"
          trackBy="intent_response_id"
          :requestParams="{ communityId: this.community.id, sortField: '', direction: '' }"
          :search="true"
        />
      </div>
      <div v-else>
        <List
          resource="previewConversationCustomReport"
          :fields="customFields"
          ref="list_global"
          :pageSize="18"
          trackBy="intent_response_id"
          :requestParams="{ communityId: this.community.id, sortField: '', direction: '' }"
          :search="true"
        />
      </div>
    </div>
    <modal-footer :footer="footer" :primary="onDownload" :tertiary="cancel"  />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import List from '@/components/digital_human/List'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import EventBus from "@/utils/EventBus";

export default {
  name: 'CreateResponse',
  components: { Loader, ModalFooter, List },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
   data() { 
    return {
      loading: false,
      fitleExtention: '',
      currentTab: 1,
      footer: {
        primaryButton: 'export to excel',
        tertiaryButton: 'cancel',
      },
      globalFields: [
        {
          name: 'intent_category_name',
          title: 'main topic',
          sortField: 'intent_category_name'
        },
        {
          name: 'intent_name_parent',
          title: 'conversation detail',
          sortField: 'intent_name_parent',
        },
        {
          name: 'intent_name_child',
          title: 'next level detail',
          sortField: 'intent_name_child'
        },    
        {
          name: 'is_active',
          title: 'active',
          sortField: 'is_active'
        },    
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text'
        },
        {
          name: 'spanish_response_text',
          title: 'translation',
          sortField: 'spanish_response_text'
        },
      ],
      customFields: [
        {
          name: 'intent_category_name',
          title: 'main topic',
          sortField: 'intent_category_name'
        },
        {
          name: 'intent_name_parent',
          title: 'conversation detail',
          sortField: 'intent_name_parent',
        },
        {
          name: 'intent_name_child',
          title: 'next level detail',
          sortField: 'intent_name_child'
        },    
        {
          name: 'disabled',
          title: 'active',
          sortField: 'disabled'
        },    
        {
          name: 'response_text',
          title: 'response',
          sortField: 'response_text'
        },
        {
          name: 'spanish_response_text',
          title: 'translation',
          sortField: 'spanish_response_text'
        },
      ],
    }
  },
  methods: {
    download(extention) {
        this.fitleExtention = extention;
        EventBus.emit('show-modal-download', { title: 'Download PDF', downloadStarted: 'download started'});
    },
    selectTab(nextTab) {
      if (this.currentTab !== nextTab) {
        this.currentTab = nextTab;
      }
    },
    downloadProcess(data, extentionFile) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `conversation-report.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
    },
    generateExcelReport() {
      this.$dhDataProvider.getOne('conversationReport', { id: this.community?.id, responseType: 'blob',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'Content-disposition': 'attachment',
          'format_file': 'xlsx'
        }})
        .then(response => {
          if (this.fitleExtention.trim() !== '') {
            this.fitleExtention = ''
            this.downloadProcess(response, 'xlsx');
          }
        })
        .catch((error) => this.notifyError(error))
        .finally(() => this.hideDownloadIndicator())
    },
    onDownload() {
      this.download('xlsx')
    },
    cancel() {
      this.redirect('conversations_responses.index')
    },
  },
  validations: {
    responseText: 'required',
  },
  created() {
    this.initValidator()
  },
  mounted() {
    EventBus.on('download-pdf', () => {
      switch (this.fitleExtention) {
        case 'xlsx':
          this.generateExcelReport()
          break;
        default:
          this.fitleExtention = ''
          break;
      }
    })
  }
}
</script>

<style scoped>
.form {
  width: 55%;
}
.label-container {
  width: 53%;
}
.active {
  color: var(--highlightColor500);
  border-bottom: 2px solid;
  border-color: var(--highlightColor500);
  margin: 0 5px 0 5px;
}
</style>
